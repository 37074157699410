var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-skeleton-loader',{attrs:{"type":"table","loading":_vm.skLoader}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-card',{attrs:{"width":"50%"}},[_c('Calendar'),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[(_vm.skLoader)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),(!_vm.selectedAccount)?_c('v-card-title',[_c('h3',[_vm._v("Selecione uma conta para ver o relatório...")])]):_vm._e(),_c('v-card-text',[(!_vm.selectedAccount)?_c('v-row',_vm._l((_vm.accounts),function(account,index){return _c('v-col',{key:index,attrs:{"md":"3"}},[_c('button',{staticClass:"accountButton",on:{"click":function($event){return _vm.enviar(account)}}},[_c('v-container',[_c('v-row',[_c('v-list-item-avatar',{staticStyle:{"margin":"16px"},attrs:{"size":"64"}},[_c('img',{attrs:{"src":(account.Institutions || {}).icon
                        || 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'}})]),_c('v-col',{staticStyle:{"align-self":"center","text-align":"start","padding":"0px"}},[_c('h3',{staticClass:"accountButton-title"},[_vm._v(_vm._s(account.name))]),_c('h4',[_vm._v(_vm._s(_vm.accountTypes[account.type]))])])],1)],1)],1)])}),1):_vm._e(),(_vm.selectedAccount)?_c('v-row',[_c('div',[_c('OrganizzeActionButton',_vm._l((_vm.accounts),function(account,index){return _c('v-list-item',{key:index,attrs:{"slot":"listExtension"},slot:"listExtension"},[_c('v-list-item-content',[_c('v-btn',{attrs:{"small":"","outlined":"","color":_vm.variables.colorPrimary},on:{"click":function($event){return _vm.enviar(account)}}},[_vm._v(" "+_vm._s(account.name)+" ")])],1)],1)}),1)],1),_c('v-list-item-avatar',{attrs:{"size":"64"}},[_c('img',{attrs:{"src":(_vm.selectedAccount.Institutions || {}).icon
              || 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'}})]),_c('v-col',{staticStyle:{"align-self":"center","text-align":"start"}},[_c('h2',{},[_vm._v(_vm._s(_vm.selectedAccount.name))]),_c('h4',{},[_vm._v(_vm._s(_vm.accountTypes[_vm.selectedAccount.type]))])])],1):_vm._e(),(_vm.selectedAccount)?_c('v-row',[_c('MyFilter')],1):_vm._e(),(_vm.selectedAccount)?_c('v-skeleton-loader',{attrs:{"type":"table","loading":_vm.loading}},[_c('Graph',{attrs:{"formatLabel":_vm.calendar.month}}),_c('div',{staticClass:"dr-title text-left ml-4 mt-4 mb-2"},[_vm._v(" Detalhamento ")]),(_vm.selectedAccount)?_c('div',{staticClass:"table"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.result,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.Records_groupByMonth",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatType(item))+" ")]}},{key:"item.Records_income",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.Records_income)))])]}},{key:"item.Records_outcome",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm._f("currency")(item.Records_outcome)))])]}},{key:"item.Records_balance",fn:function(ref){
              var item = ref.item;
return [_c('span',{style:([{color: item.Records_balance >= 0 ? 'green': 'red' }])},[_vm._v(" "+_vm._s(_vm._f("currency")(item.Records_result))+" ")])]}},{key:"item.Records_result",fn:function(ref){
              var item = ref.item;
return [_c('span',{style:([{color: item.Records_result > 0 ? 'green': 'red' }])},[_vm._v(" "+_vm._s(_vm._f("currency")(item.Records_balance)))])]}}],null,false,1738334804)})],1):_vm._e()],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }