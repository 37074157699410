<template>
  <v-skeleton-loader type="table" :loading="skLoader">
    <v-row justify="center" no-gutters>
      <v-card width="50%">
        <Calendar/>

        <v-row no-gutters justify="center">
          <v-progress-circular v-if="skLoader" indeterminate color="primary"></v-progress-circular>
        </v-row>

        <v-card-title v-if="!selectedAccount">
          <h3>Selecione uma conta para ver o relatório...</h3>
        </v-card-title>

        <v-card-text>
          <v-row v-if="!selectedAccount">
            <v-col v-for="(account, index) in accounts" :key="index" md="3">
              <button class="accountButton" @click="enviar(account)">
                <v-container>
                  <v-row>
                    <v-list-item-avatar size="64" style="margin: 16px;">
                      <img
                        :src="(account.Institutions || {}).icon
                          || 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'"/>
                    </v-list-item-avatar>

                    <v-col style="align-self: center; text-align: start; padding: 0px;">
                      <h3 class="accountButton-title">{{account.name}}</h3>
                      <h4>{{accountTypes[account.type]}}</h4>
                    </v-col>
                  </v-row>
                </v-container>
              </button>
            </v-col>
          </v-row>

          <v-row v-if="selectedAccount">
            <div>
              <OrganizzeActionButton>
                <v-list-item
                  slot="listExtension"
                  v-for="(account, index) in accounts"
                  :key="index">
                  <v-list-item-content>
                    <v-btn
                      small
                      outlined
                      :color="variables.colorPrimary"
                      @click="enviar(account)">
                      {{account.name}}
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </OrganizzeActionButton>
            </div>

            <v-list-item-avatar size="64">
              <img :src="(selectedAccount.Institutions || {}).icon
                || 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'"/>
            </v-list-item-avatar>

            <v-col style="align-self: center; text-align: start;">
              <h2 style="">{{selectedAccount.name}}</h2>
              <h4 style="">{{accountTypes[selectedAccount.type]}}</h4>
            </v-col>
          </v-row>

          <v-row v-if="selectedAccount">
            <MyFilter/>
          </v-row>

          <v-skeleton-loader v-if="selectedAccount" type="table" :loading="loading">
            <Graph :formatLabel="calendar.month"/>


            <div class="dr-title text-left ml-4 mt-4 mb-2">
              Detalhamento
            </div>

            <div v-if="selectedAccount" class="table">
              <v-data-table
                :headers="headers"
                :items="result"
                hide-default-footer
                disable-pagination
                >

                <!-- Periodo -->
                <template v-slot:item.Records_groupByMonth="{ item }">
                    {{ formatType(item) }}
                </template>

                <!-- Entradas -->
                <template v-slot:item.Records_income="{ item }">
                    <span style="color: green"> {{ item.Records_income | currency }}</span>
                </template>

                <!-- Saidas -->
                <template v-slot:item.Records_outcome="{ item }">
                    <span style="color: red">{{ item.Records_outcome | currency }}</span>
                </template>

                <!-- Resultado -->
                <template v-slot:item.Records_balance="{ item }">
                    <span :style="[{color: item.Records_balance >= 0 ? 'green': 'red' }]">  {{ item.Records_result | currency }} </span>
                </template>

                <!-- Saldo -->
                <template v-slot:item.Records_result="{ item }">
                  <span :style="[{color: item.Records_result > 0 ? 'green': 'red' }]"> {{ item.Records_balance | currency }}</span>
                </template>

              </v-data-table>
            </div>
          </v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-row>
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { accountTypesEnum } from '@/utils/tempUtils'
import variables from '@/assets/styles/helpers/_variables.scss'
import Events from '@/core/service/events'
import moment from 'moment'

const namespaceStore = 'organizzeRelatoriosContas'

export default {
  name: 'OrganizzeRelatoriosContas',

  props: {},
  directives: {},

  components: {
    OrganizzeActionButton: () => import('@/views/components/organizze/actionButton'),
    MyFilter: () => import('./components/myFilter'),
    Calendar: () => import('./components/calendar'),
    Graph: () => import('./components/graph')
  },

  data: () => ({
    skLoader: false,
    loading: false,
    selectedAccount: null,
    accountTypes: [],
    filter: {
      selectedSeenByType: { text: 'Pagamento', id: 'dt_paid' },
      selectedTimeType: 'month',
      isPendingOperations: true
    },
    calendar: {
      month: moment().month(),
      year: moment().year(),
      week: moment().week(),
      type: 'month',
      text: moment().format('MMMM [de] YYYY')
    },
    headers: [
      { text: 'período(pgamento vencimento)', value: 'Records_groupByMonth' },
      { text: 'Entradas', value: 'Records_income' },
      { text: 'Saídas', value: 'Records_outcome' },
      { text: 'Resultado', value: 'Records_balance' },
      { text: 'Saldo', value: 'Records_result' }
    ]
  }),

  created () {
    const self = this

    Events.$on('filter::sendData', val => {
      self.filter = val
      self.enviar(self.selectedAccount)
    })

    Events.$on('calendar::sendData', val => {
      self.calendar = val
      self.enviar(self.selectedAccount)
    })
  },

  mounted () {
    const self = this

    self.accountTypes = accountTypesEnum()

    self.skLoader = true
    self.getAccounts().then(() => {}).catch(e => { console.error(e) }).finally(() => { self.skLoader = false })
  },

  computed: {
    ...mapGetters(namespaceStore, ['accounts', 'result']),
    variables: () => variables
  },

  watch: {},

  methods: {
    ...mapActions(namespaceStore, ['getAccounts', 'submit']),

    enviar (account) {
      const self = this

      self.selectedAccount = account

      const _addLFilter = {
        accountId: self.selectedAccount.id,
        mes: self.calendar.month,
        text: self.calendar.text,
        type: self.filter.selectedTimeType,
        year: self.calendar.year,
        week: self.calendar.week,
        typeFilter: self.filter.selectedSeenByType.id,
        pendentes: self.filter.isPendingOperations
      }

      self.loading = true
      self.submit({ _addLFilter }).then(() => {}).catch(e => {
        console.error(e)
      }).finally(() => {
        self.skLoader = false
        self.loading = false
      })
    },

    formatType(v) {
      const self = this
      const type = self.filter.selectedTimeType

      if (type ==='week') {
        const end = moment().weeks(v.Records_Week + 1).year(v.Records_Year).startOf('weeks').format('DD MMM')
        return moment().weeks(v.Records_Week + 1).year(v.Records_Year).endOf('weeks').format(`[${end} à] DD MMM`)
      }

      if (type ==='day') return moment(v.Records_Date).format('DD/MM/YYYY')

      return `${v.Records_Month}/${v.Records_Year}`
    }
  },

  validations () { return {} }
}
</script>